<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Open exams - Online exam</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Open exams - Online exam
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Search by name" dense outlined clearable v-model="search.info"  v-on:keyup.enter="searchOngoingOnlineExam"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Enrolment key" dense outlined clearable v-model="search.exam_key"  v-on:keyup.enter="searchOngoingOnlineExam"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select v-model="search.score_id" :items="scores"
                            label="SCORE"
                            item-text="name"  v-on:keyup.enter="searchOngoingOnlineExam"
                            item-value="id"
                            clearable
                            outlined
                            dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchOngoingOnlineExam"
                         class="btn btn-primary btn-search "
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                  <tr class="text-left">
                    <th class="px-3">Candidate name</th>
                    <th class="px-3">Enrolment key</th>
                    <th class="px-3">Exam name</th>
                    <th class="px-3">Exam start date/time</th>

                    <th class="px-3">Options</th>
                  </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item,index) in ongoing_online_exams" :key="index">
                    <td class="px-3">
                      <span   class=" font-weight-bolder font-size-lg">
                        {{item.full_name}}
                      </span>
                    </td>
                    <td class="px-3">
                      <a @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.exam_key}}
                      </a>
                    </td>
                    <td class="px-3">
                      <span class="mb-1 font-size-lg font-weight-medium">
                        {{item.product_name}}
                      </span>
                    </td>
                    <td class="px-3">
                      <span  class="text-dark-75 font-weight-medium  mb-1 font-size-lg">
                        {{ item.start_time_format ? item.start_time_format : 'N/A' }}
                      </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text  tag="div" class="navi-item">
                              <a @click="openCandidateDialog(item.candidate_id)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fas fa-eye</v-icon>
                                </span>
                                <span class="navi-text">Candidate details</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openDialog(item)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fas fa-user</v-icon>
                                </span>
                                <span class="navi-text">Void Exam</span>
                              </a>
                            </b-dropdown-text>



                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="ongoing_online_exams.length == 0">
                    <td colspan="6" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="ongoing_online_exams.length > 0"
                            class="pull-right mt-7"
                            @input="getAllOngoingOfficialExams"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
      </div>
    </div>
    </div>
    <v-dialog v-model="dialog" persistent  fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              scrollable>
      <v-card>
        <v-toolbar flat>
          <v-card-title>
            <span>Void Exam</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row >
              <v-col md="12">
                <div class="font-weight-normal h6">
                  <strong>Candidate Full name :</strong> {{ongoing_online_exam.full_name}}
                </div>
                <div class="font-weight-normal h6">
                  <strong> Enrolment key:</strong> {{ongoing_online_exam.exam_key}}
                </div>
                <div class="font-weight-normal h6">
                  <strong> Product name:</strong> {{ongoing_online_exam.product_name}}
                </div>
                
              </v-col>
              <v-col md="12">
                <v-textarea label="Remarks" dense outlined v-model="remarks" :error="$v.remarks.$error"></v-textarea>
                <span class="text-danger" v-if="$v.remarks.$error">This information is required</span>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btn btn-primary" dark medium @click="updateVoidExam()" :loading="loading">
            Save
          </v-btn>
          <v-btn color="btn btn-standard" dark medium
              @click="closeDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="candidate_detail_dialog" width="600px">
      <v-card>
        <div class="card-body ">


          <div class="d-flex align-items-center">

            <div>
              <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary mb-1">
                {{candidateDetail.full_name}}

              </a>
              <div class="font-weight-regular mb-1">
                <strong>Enrolment key</strong> : {{candidateDetail.exam_key}}
              </div>
              <div class="font-weight-regular mb-1">
                <strong>Enrolment pin</strong> : {{candidateDetail.exam_pin}}
              </div>
              <div class="font-weight-regular mb-1" v-html="'<strong>Exam status </strong> : &nbsp;'+candidateDetail.exam_status_badge">
                <strong>Exam status</strong> :
              </div>
              <div class="font-weight-regular mb-1" v-if="candidateDetail.gender">
                <strong>Gender</strong> : {{candidateDetail.gender}}
              </div>
            </div>
          </div>

        </div>

      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import OnlineExamMarkerService from "@/services/online-marker/OnlineExamMarkerService";
import CandidateService from "@/services/candidate/CandidateService";
import {required} from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
const score=new ScoreService();
const candidate=new CandidateService();
const onlineExamMarker=new OnlineExamMarkerService();
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  validations: {
    remarks:{required}
  },
  data(){
    return{
      search:{
        exam_key:'',
      },
      isLoading:false,
      ongoing_online_exams:[],
      candidate_detail_dialog:false,
      dialog:false,
      loading:false,
      ongoing_online_exam:{},
      remarks:'',
      total: null,
      perPage: null,
      page: null,
      candidateDetail:{},
      scores:[],
    }
  },
  methods:{
    getAllOngoingOfficialExams(){
      this.isLoading=true;
      onlineExamResult
          .getAllOngoingOfficialExams(this.search, this.page)
          .then(response => {
            this.ongoing_online_exams=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    searchOngoingOnlineExam(){
      this.getAllOngoingOfficialExams();
    },
    closeDialog(){
      this.dialog=false;
      this.ongoing_online_exam={};
    },
    openDialog(item){
      this.remarks='';
      this.ongoing_online_exam=item;
      this.dialog=true;
    },
    openCandidateDialog(candidateId){
      this.candidate_detail_dialog=true;
      this.getCandidateDetail(candidateId)
    },
    closeCandidateDialog(){
      this.candidate_detail_dialog=false;
      this.candidateDetail={};
    },
    getCandidateDetail(getCandidateDetail){
      candidate
          .show(getCandidateDetail)
          .then(response => {
              this.candidateDetail=response.data.candidate;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    updateVoidExam(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        let data = {};
        data['candidate_id'] = this.ongoing_online_exam.candidate_id;
        data['remarks'] = this.remarks;
        onlineExamMarker
            .updateOnlineMarkerVoid(data)
            .then(response => {
              this.closeDialog();
            })
            .catch((err) => {

            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
  },
  mounted() {
    this.getAllOngoingOfficialExams();
    this.getAllScore();
  }
}
</script>